import React from 'react';
import type { BrandConfig } from '../config.types';

//BrandingLogos
import logo from "./assets/logo.svg";
import { ReactComponent as LogoComponent } from "./assets/logo.svg?react";
import logoPlaceholder from "./assets/logo_placeholder.svg";
import logoSimple from "./assets/logo_simple.svg";

//ErrorPageImages
import errorPageImageDesktop from "./assets/errorPageDesktop.png";
import errorPageImageMobile from "./assets/errorPageMobile.png";

//Theme
const colors = {
    primary120: "#3F842A",
    primary: "#459B2B",
    primary60: "#DAEBD5",
};

const svgColorFilter: string = "invert(45%) sepia(82%) saturate(415%) hue-rotate(61deg) brightness(96%) contrast(91%)"

//SocialMedia links
const socialMediaLinks = {
    facebook: 'https://www.facebook.com/innpoland',
    instagram: 'https://www.instagram.com/innpoland.pl/',
    tiktok: 'https://www.tiktok.com/@innpolandpl',
    youtube: 'https://www.youtube.com/@INNPoland_'
};

//Breadcrumbs/Footer title
const brandTitle = (
    <>
        INN<span>:</span>Poland
    </>
);

const brandUrlName = "innpoland";

//ErrorPageBranding
const errorPageText = (
    <>
        Ten wykres wyszedł poza skalę. Przeczytaj inne teksty na interesujące Cię tematy.
    </>
);

//FooterDescription
const footerDescription = "Przyszłość już tu jest. Biznes, technologia, nauka, ekologia.";

//PushPushGo worker
const pushPushWorkerUrl = "https://s-eu-1.pushpushgo.com/66952b2fc05a73d2a0685f89/worker.js";

//Export config
const BrandConifg: BrandConfig = {
    LogoComponent,
    logo, logoPlaceholder, logoSimple,
    colors, svgColorFilter,
    brandTitle,
    socialMediaLinks,
    errorPageText, errorPageImageDesktop, errorPageImageMobile,
    footerDescription,
    pushPushWorkerUrl,
    brandUrlName,
    inActive: window.BRAND == 'innpoland'
};

export default BrandConifg;