import React from 'react';
import type { BrandConfig } from '../config.types';
//import { useStores } from "../../helpers/useStores";

//BrandingLogos
import logo from "./assets/logo_placeholder.svg";
import { ReactComponent as LogoComponent } from "./assets/logo_placeholder.svg?react";
import logoPlaceholder from "./assets/logo.svg";
import logoSimple from "./assets/logo.svg";

//ErrorPageImages
import errorPageImageDesktop from "./assets/errorPageDesktop.png";
import errorPageImageMobile from "./assets/errorPageMobile.png";

//Theme
const colors = {
    // stare kolory
    //primary120: "#3F842A",
    //primary: "#459B2B",
    //primary60: "#DAEBD5",

    primary120: "#7a9f00", // Darker yellow-green (darker version of #b0e000)
    primary: "#b0e000",     // Yellow-green
    primary60: "#d9e84f",   // Lighter yellow-green (lighter version of #b0e000)

    categories: {
        '167': {
            // tech
            primary120: "#7400cc",  // Darker purple (darker version of #9500fe)
            primary: "#9500fe",      // Purple
            primary60: "#d17aff",    // Lighter purple (lighter version of #9500fe)
        },
        '243': {
            // finanse osobiste
            primary120: "#e25c00",  // Darker orange (darker version of #ff7200)
            primary: "#ff7200",      // Orange
            primary60: "#ff9f66",    // Lighter orange (lighter version of #ff7200)
        },
        '139': {
            // rozwój i praca (obecnie kariera)
            primary120: "#ccbe00",  // Darker yellow (darker version of #ffe600)
            primary: "#ffe600",      // Bright yellow
            primary60: "#ffff66",    // Lighter yellow (lighter version of #ffe600)
        },
        '149': {
            // eco i nauka
            primary120: "#00cc00",  // Darker green (darker version of #01ff0d)
            primary: "#01ff0d",      // Bright green
            primary60: "#66ff66",    // Lighter green (lighter version of #01ff0d)
        },
        '115': {
            // biznes
            primary120: "#0050b3",  // Darker blue (darker version of #0067ff)
            primary: "#0067ff",      // Blue
            primary60: "#66aaff",    // Lighter blue (lighter version of #0067ff)
        },
        '241': {
            // magazyn
            primary120: "#b30099",  // Darker pink (darker version of #ff04d0)
            primary: "#ff04d0",      // Pink
            primary60: "#ff66b3",    // Lighter pink (lighter version of #ff04d0)
        },
    },

};

const svgColorFilter: string = "invert(45%) sepia(82%) saturate(415%) hue-rotate(61deg) brightness(96%) contrast(91%)"

//SocialMedia links
const socialMediaLinks = {
    facebook: 'https://www.facebook.com/innpoland',
    instagram: 'https://www.instagram.com/innpoland.pl/',
    tiktok: 'https://www.tiktok.com/@innpolandpl',
    youtube: 'https://www.youtube.com/@INNPoland_'
};

//Breadcrumbs/Footer title
const brandTitle = (
    <>
        <i>INN</i>Poland.
    </>
);

//ErrorPageBranding
const errorPageText = (
    <>
        Ups! Nic tu nie ma
    </>
);

//FooterDescription
const footerDescription = "Przyszłość już tu jest. Biznes, technologia, nauka, ekologia.";

//PushPushGo worker
const pushPushWorkerUrl = "https://s-eu-1.pushpushgo.com/66952b2fc05a73d2a0685f89/worker.js";

//Export config
const BrandConifg: BrandConfig = {
    LogoComponent,
    logo, logoPlaceholder, logoSimple,
    colors, svgColorFilter,
    brandTitle,
    socialMediaLinks,
    errorPageText, errorPageImageDesktop, errorPageImageMobile,
    footerDescription,
    pushPushWorkerUrl,
    inActive: window.BRAND != 'innpoland'
};

export default BrandConifg;