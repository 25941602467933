import React from "react";
import brandsConfig from "./config.brands";

export default brandsConfig;

const brandsConfigEntries = Object.entries(brandsConfig);

/* Brands Stitches config */
export const StitchesBrandsConfig = {
    mainColor: brandsConfigEntries.reduce((config, [brandName, brandConfig]) => {
        const { categories, ...colorsConfig } = brandConfig.colors;
        config[brandName] = colorsConfig;

        return config;
    }, {} as any),
    mainSVGFilter: brandsConfigEntries.reduce((config, [brandName, brandConfig]) => {
        config[brandName] = {
            svgPrimary: brandConfig.svgColorFilter
        };

        return config;
    }, {} as any),
    defaultColors: brandsConfigEntries.reduce((config, [brandName, brandConfig]) => {
        config[`${brandName}Primary`] = brandConfig.colors.primary;

        return config;
    }, {} as any),
    customThemesColors: brandsConfigEntries.reduce((config, [brandName, brandConfig]) => {
        config[brandName] = config?.[brandName] || {};

        //TODO: You can add here additional brand themes ex. tag.
        if (brandConfig.colors?.categories) {
            Object.entries(brandConfig.colors?.categories).forEach(([catgoryOID, colorsConfig]) => {
                config[brandName][`categories-${catgoryOID}`] = colorsConfig;
            });
        };

        return config;
    }, {} as any),
};

/* Brands UIStoreTheme config */
export const UIStoreBrandsTheme = brandsConfigEntries.reduce((config, [brandName, brandConfig]) => {
    config[brandName] = {
        mainColor: brandConfig.colors.primary,
        secondColor: brandConfig.colors.primary,
        logo: brandConfig.logo,
        logoSimple: brandConfig.logoSimple,
        logoPlaceholder: brandConfig.logoPlaceholder,
        plista_publicKey: brandConfig?.plistaPublicKey ?? null
    };

    return config;
}, {} as any);

/* BrandsLogo component */
export const LogoBrandsVariants = {
    /*Logo*/
    components: brandsConfigEntries.reduce((config, [brandName, brandConfig]) => {
        config[brandName] = React.createElement(brandConfig.LogoComponent, {});

        return config;
    }, {} as any),
    dotsDefaultVariantsColor: brandsConfigEntries.reduce((variants, [brandName]) => {
        variants[brandName] = {
            "& svg": {
                "& [class$=dots]": {
                    fill: `$${brandName}Primary`
                }
            }
        }

        return variants;
    }, {} as any),
    dotsColorVariantsColor: brandsConfigEntries.map(([brandName]) => ({
        brand: brandName,
        color: 'color',
        css: {
            backgroundColor: `$${brandName}Primary`,
            "& svg": {
                "& [class$=dots]": {
                    fill: "$grayscale0"
                }
            }
        }
    }), {} as any)
};

/* Breadcrumbs/Navbar brands */
export const TitleBrandsVariants = {
    breadcrumbsColorVariants: brandsConfigEntries.reduce((variants, [brandName]) => {
        variants[brandName] = {
            span: {
                color: `$${brandName}Primary`,
            },
            i: {
                color: `$primary`,
            }
        }

        return variants;
    }, {} as any)
};

/* CategoryBadge brands */
export const CategoryBadgeBrandsVariants = {
    categoryColorVariants: brandsConfigEntries.reduce((variants, [brandName]) => {
        variants[brandName] = {
            '& div,& span': {
                backgroundColor: `$${brandName}Primary`,
            }
        }

        return variants;
    }, {} as any)
}

/* ErrorPage config */
export const ErrorPageConfig = {
    description: brandsConfigEntries.reduce((config, [brandName, brandConfig]) => {
        config[brandName] = brandConfig.errorPageText;

        return config;
    }, {} as any),
    backgroundDesktopVariants: brandsConfigEntries.reduce((variants, [brandName, brandConfig]) => {
        variants[brandName] = {
            "@bp4": {
                backgroundImage: `url("${brandConfig.errorPageImageDesktop}")`,
            }
        }

        return variants;
    }, {} as any),
    backgroundMobileVariants: brandsConfigEntries.reduce((variants, [brandName, brandConfig]) => {
        variants[brandName] = {
            backgroundImage: `url("${brandConfig.errorPageImageMobile}")`,
        }

        return variants;
    }, {} as any)
};

/* GetSocialMedia links */
export const getSocialMediaLinks = (brand: Config.Brand) => {
    return {
        ...brandsConfig['natemat'].socialMediaLinks,
        ...brandsConfig[brand].socialMediaLinks
    };
};